<template>
  <el-dialog
    top="30vh"
    width="50vw"
    visible
    append-to-body
    custom-class="payment-method-modal rounded"
    :show-close="false"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between p-4">
        <h2>{{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.title') }}</h2>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="p-4">
      <el-radio-group v-model="model.paymentMethod" class="d-flex flex-column gap-4">
        <el-radio label="none" class="d-flex mx-0">
          <p class="px-4">
            {{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.none') }}
          </p>
        </el-radio>
        <template v-if="bankAccounts.length">
          <el-radio
            v-for="bankAccount in bankAccounts"
            :key="bankAccount.id"
            :label="`${PAYMENT_METHOD_TYPE.BANK_TRANSFER}_${bankAccount.id}`"
            class="d-flex align-items-center mx-0"
            @change="onBankAccountSelected(bankAccount.id)"
          >
            <BankTransferIcon width="16" height="16" />
            <p class="px-1">
              {{ `${$t('terms.supplierTerms.generalTerms.bankTransfer')} - ${getBankAccountDetails(bankAccount)}` }}
            </p>
          </el-radio>
        </template>
        <el-radio v-else disabled class="d-flex align-items-center mx-0">
          <BankTransferIcon width="16" height="16" />
          <p class="px-1">
            {{ $t('terms.supplierTerms.generalTerms.bankTransfer') }} -
            {{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.notDefinedBySupplier') }}
          </p>
        </el-radio>
        <el-radio :label="DIRECT_DEBIT_TYPE.BANK_TRANSFER_DIRECT_DEBIT" class="d-flex align-items-center mx-0">
          <div class="position-relative d-flex align-self-center">
            <BankTransferIcon width="16" height="16" />
            <RecurringIcon class="position-absolute badge-position" width="14" height="14" />
          </div>
          <p class="px-1">
            {{
              `${$t('terms.supplierTerms.generalTerms.bankTransfer')} - ${$t(
                'terms.supplierTerms.generalTerms.paymentMethodModal.directDebit'
              )}`
            }}
          </p>
          <div
            v-if="model.paymentMethod === DIRECT_DEBIT_TYPE.BANK_TRANSFER_DIRECT_DEBIT"
            class="text-typography-primary fw-normal m-5 mt-4 mb-1"
          >
            <el-form ref="form" :model="model" :show-message="false">
              <el-form-item prop="directDebitDay" required class="mb-1">
                <div class="d-flex align-items-center gap-1">
                  <span v-if="$direction === 'rtl'">
                    {{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.day') }}
                  </span>
                  <el-select v-model="model.directDebitDay" size="mini" style="width: 70px">
                    <el-option v-for="option in selectOptions" :key="option" :label="option" :value="option" />
                  </el-select>
                  <span>{{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.ofTheMonth') }}</span>
                </div>
              </el-form-item>
            </el-form>
            <el-checkbox v-model="model.beforeBilling">
              <p>
                {{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.beforeBilling') }}
              </p>
            </el-checkbox>
          </div>
        </el-radio>
        <el-radio :label="PAYMENT_METHOD_TYPE.CREDIT_CARD" class="d-flex align-items-center mx-0">
          <CreditCardIcon width="16" height="16" />
          <p class="px-1">
            {{ $t('terms.supplierTerms.generalTerms.creditCard') }}
          </p>
        </el-radio>
        <el-radio :label="DIRECT_DEBIT_TYPE.CREDIT_CARD_DIRECT_DEBIT" class="d-flex align-items-center mx-0">
          <div class="position-relative d-flex align-self-center">
            <CreditCardIcon width="16" height="16" />
            <RecurringIcon class="position-absolute badge-position" width="14" height="14" />
          </div>
          <p class="px-1">
            {{
              `${$t('terms.supplierTerms.generalTerms.creditCard')} - ${$t(
                'terms.supplierTerms.generalTerms.paymentMethodModal.directDebit'
              )}`
            }}
          </p>
          <div
            v-if="model.paymentMethod === DIRECT_DEBIT_TYPE.CREDIT_CARD_DIRECT_DEBIT"
            class="text-typography-primary fw-normal m-5 mt-4 mb-1"
          >
            <el-form ref="form" :model="model" :show-message="false">
              <el-form-item prop="directDebitDay" required class="mb-1">
                <div class="d-flex align-items-center gap-1">
                  <span v-if="$direction === 'rtl'">
                    {{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.day') }}
                  </span>
                  <el-select v-model="model.directDebitDay" size="mini" style="width: 70px">
                    <el-option v-for="option in selectOptions" :key="option" :label="option" :value="option" />
                  </el-select>
                  <span>{{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.ofTheMonth') }}</span>
                </div>
              </el-form-item>
            </el-form>
            <el-checkbox v-model="model.beforeBilling">
              <p>
                {{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.beforeBilling') }}
              </p>
            </el-checkbox>
          </div>
        </el-radio>
        <el-radio :label="PAYMENT_METHOD_TYPE.CHEQUE" class="d-flex align-items-center mx-0">
          <ChequeIcon width="16" height="16" />
          <p class="px-1">
            {{ $t('terms.supplierTerms.generalTerms.cheque') }}
          </p>
        </el-radio>
      </el-radio-group>
    </div>
    <template #footer>
      <div class="p-4 pt-0">
        <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button @click="save">{{ $t('commons.save') }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, watch, computed } from 'vue';

import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { CloseIcon, CreditCardIcon, ChequeIcon, BankTransferIcon, RecurringIcon } from '@/assets/icons';
import { PAYMENT_METHOD_TYPE, DIRECT_DEBIT_TYPE } from '@/modules/payment/types';
import { useSupportedBanks } from '@/modules/payment';

const getPaymentMethod = (paymentTerm) => {
  switch (paymentTerm?.paymentMethod) {
    case PAYMENT_METHOD_TYPE.BANK_TRANSFER:
      return paymentTerm.directDebit
        ? DIRECT_DEBIT_TYPE.BANK_TRANSFER_DIRECT_DEBIT
        : `${PAYMENT_METHOD_TYPE.BANK_TRANSFER}_${paymentTerm.bankAccountId}`;
    case PAYMENT_METHOD_TYPE.CREDIT_CARD:
      return paymentTerm.directDebit ? DIRECT_DEBIT_TYPE.CREDIT_CARD_DIRECT_DEBIT : PAYMENT_METHOD_TYPE.CREDIT_CARD;
    case PAYMENT_METHOD_TYPE.CHEQUE:
      return PAYMENT_METHOD_TYPE.CHEQUE;
    default:
      return 'none';
  }
};

export default {
  components: { Button, CloseIcon, CreditCardIcon, ChequeIcon, BankTransferIcon, RecurringIcon },
  props: {
    loading: { type: Boolean, default: false },
    bankAccounts: { type: Array, default: () => [] },
    paymentTerm: { type: Object, default: () => null },
  },
  setup(props) {
    const model = ref({
      paymentMethod: getPaymentMethod(props.paymentTerm),
      directDebit: props.paymentTerm?.directDebit,
      directDebitDay: props.paymentTerm?.directDebitDay,
      beforeBilling: props.paymentTerm?.beforeBilling,
      bankAccountId: props.paymentTerm?.bankAccountId,
    });

    const isModalDirty = ref(false);

    watch(model, () => (isModalDirty.value = true), { deep: true });
    watch(
      () => model.paymentMethod,
      (newPaymentMethod) => {
        model.value = {
          paymentMethod: newPaymentMethod,
          directDebitDay: null,
          beforeBilling: false,
        };
      },
      { deep: true }
    );
    const { currentTenant } = useTenancy();

    const countryCode = computed(() => currentTenant.value?.countryCode);
    const { supportedBanks } = useSupportedBanks(countryCode);
    return {
      supportedBanks,
      countryCode,
      selectOptions: Array.from(Array(31), (_, i) => (i + 1).toString()),
      model,
      isModalDirty,
      PAYMENT_METHOD_TYPE,
      DIRECT_DEBIT_TYPE,
    };
  },
  methods: {
    getBankAccountDetails(bankAccount) {
      const { accountName, branchNumber, accountNumber, routingNumber, bankNumber } = bankAccount;
      const bankName = this.supportedBanks[bankNumber];
      const addBankAccountModal = 'payment.addBankAccountModal';
      const bankAccountNumberTranslation = this.$t('businessSettings.paymentSettings.bankAccountNumber');

      const ilFormat = ` ${accountName}, ${bankName}(${bankNumber}), ${this.$t(
        `${addBankAccountModal}.bankBranchNumber`
      )} ${branchNumber}, ${bankAccountNumberTranslation} ${accountNumber}`;
      const usFormat = ` ${this.$t(`${addBankAccountModal}.bankAccountName`)}: ${accountName}, ${this.$t(
        `${addBankAccountModal}.bankRoutingNumber`
      )}: ${routingNumber}, ${bankAccountNumberTranslation}: ${accountNumber}`;
      return this.countryCode === 'IL' ? ilFormat : usFormat;
    },
    onBankAccountSelected(bankAccountId) {
      this.model.paymentMethod = `${PAYMENT_METHOD_TYPE.BANK_TRANSFER}_${bankAccountId}`;
      this.model.bankAccountId = bankAccountId;
    },
    async save() {
      if (!this.isModalDirty) {
        this.$emit('close');
        return;
      }
      let paymentMethodData = {
        paymentMethod: null,
        directDebit: null,
        directDebitDay: null,
        beforeBilling: null,
        bankAccountId: null,
      };

      const valid = this.$refs.form ? await this.$refs.form.validate().catch((err) => err) : true;
      if (!valid) return;

      switch (this.model.paymentMethod) {
        case DIRECT_DEBIT_TYPE.CREDIT_CARD_DIRECT_DEBIT:
          paymentMethodData = await this.buildDirectDebitData(PAYMENT_METHOD_TYPE.CREDIT_CARD);
          break;
        case DIRECT_DEBIT_TYPE.BANK_TRANSFER_DIRECT_DEBIT:
          paymentMethodData = await this.buildDirectDebitData(PAYMENT_METHOD_TYPE.BANK_TRANSFER);
          break;
        case PAYMENT_METHOD_TYPE.CREDIT_CARD:
        case PAYMENT_METHOD_TYPE.CHEQUE:
          paymentMethodData.paymentMethod = this.model.paymentMethod;
          break;
        case 'none':
          break;
        default: {
          if (this.model.paymentMethod.startsWith(PAYMENT_METHOD_TYPE.BANK_TRANSFER)) {
            paymentMethodData.paymentMethod = PAYMENT_METHOD_TYPE.BANK_TRANSFER;
            paymentMethodData.bankAccountId = this.model.bankAccountId;
            break;
          }
          this.$emit('close');
          break;
        }
      }
      this.$emit('update', paymentMethodData);
    },
    async buildDirectDebitData(paymentMethod) {
      const valid = await this.$refs.form.validate().catch((err) => err);
      if (!valid) return;
      return { ...this.model, paymentMethod, directDebit: true };
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .payment-method-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
  .el-form-item__content {
    line-height: 1;
  }
  .el-radio__input + .el-radio__label {
    line-height: 1.2;
    white-space: normal;
    display: flex;
    align-items: center;
  }
}

.badge-position {
  top: 25%;
  [dir='rtl'] & {
    left: 100%;
    transform: translate(-50%, -50%);
  }
  [dir='ltr'] & {
    transform: translate(-50%, -50%) scaleX(-1);
  }
}
</style>
