import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useTerms(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(TERMS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));
  const terms = useResult(result, []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useTerms', err);
    error();
  });

  return {
    terms,
    loading,
    refetch,
  };
}

export function useTermsNew(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(TERMS_NEW_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));
  const terms = computed(() => (result.value ? result.value.termsNew?.nodes ?? [] : []));
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useTerms', err);
    error();
  });

  return {
    terms,
    loading,
    refetch,
  };
}

export const TERMS_NEW_QUERY = gql`
  query terms($type: TermTypeNew, $supplierId: ID, $businessId: ID) {
    termsNew(type: $type, supplierId: $supplierId, businessId: $businessId) {
      nodes {
        id
        supplierId
        fromDate
        type: typeNew
        paymentMethod
        paymentDueNet
        beforeBilling
        directDebitDay
        directDebit
        bankAccountId
        eom
      }
    }
  }
`;

export const TERMS_QUERY = gql`
  query terms($type: TermTypeNew, $supplierId: ID, $businessId: ID) {
    terms(type: $type, supplierId: $supplierId, businessId: $businessId) {
      id
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;

export const TERM_UPDATE_MUTATION = gql`
  mutation termUpdateMutation($termId: ID!, $termUpdateInput: TermUpdateInput!) {
    termUpdate(termId: $termId, termUpdateInput: $termUpdateInput) {
      id
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;

export const TERM_UPDATE_MUTATION_NEW = gql`
  mutation termUpdateMutation($termId: ID!, $termUpdateInput: TermUpdateInputNew!) {
    termUpdateNew(termId: $termId, termUpdateInput: $termUpdateInput) {
      id
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;

export const TERM_CREATE_MUTATION = gql`
  mutation termCreateMutation($termCreateInput: TermCreateInput!) {
    termCreate(termCreateInput: $termCreateInput) {
      id
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;

export const TERM_CREATE_MUTATION_NEW = gql`
  mutation termCreateMutation($termCreateInput: TermCreateInputNew!) {
    termCreateNew(termCreateInput: $termCreateInput) {
      id
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;
