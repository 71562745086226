<template>
  <el-dialog top="12vh" width="32rem" visible append-to-body custom-class="rounded" :show-close="false">
    <template #title>
      <div class="d-flex justify-content-between">
        <div>
          <h2>{{ $t('terms.supplierTerms.generalTerms.paymentDueModal.title') }}</h2>
          <p>{{ supplier }}</p>
        </div>
        <Button type="icon" class="p-0 text-typography-primary action-btn" :disabled="loading" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="p-4">
      <el-radio-group v-model="paymentDueNetTerm" class="d-flex flex-column">
        <el-radio v-for="option in predefinedDueOptions" :key="option.paymentDueNet" :label="option" class="mb-5 m-0">
          {{
            $tc(
              `payment.paymentTable.paymentTermsPopover.paymentDueNet${option.eom ? 'Eom' : ''}`,
              option.paymentDueNet,
              {
                count: option.paymentDueNet,
              }
            )
          }}
        </el-radio>
        <el-radio :label="PAYMENT_DUE_RADIO_STATE_PERSONALIZED">
          {{ $t('terms.supplierTerms.generalTerms.paymentDueModal.personallyAdjusted') }}
        </el-radio>
      </el-radio-group>
      <div v-if="paymentDueNetTerm === PAYMENT_DUE_RADIO_STATE_PERSONALIZED" class="personalized-selection">
        <div class="personalized-selection__text">
          {{ $t(`terms.supplierTerms.generalTerms.paymentDueModal.${eomByCountryCode ? 'net' : 'billing'}`) }}
        </div>
        <div class="personalized-selection__input-container">
          <span v-if="$direction === 'rtl'" class="personalized-selection__input-prefix">+</span>
          <input v-model="personalizedPaymentDueNet" class="personalized-selection__input" />
        </div>
      </div>
      <div class="input-warning" :style="{ visibility: validationError ? 'visible' : 'hidden' }">
        <NoticeIcon class="input-warning__icon" />
        <span
          v-if="validationError === INPUT_VALIDATION_REASONS.INPUT_IS_NOT_A_VALID_NUMBER"
          class="input-warning__text"
        >
          {{ $t('terms.supplierTerms.generalTerms.paymentDueModal.inputIsNotANumber') }}</span
        >
        <span
          v-else-if="validationError === INPUT_VALIDATION_REASONS.NUMBER_IS_BIGGER_THAN_MAX"
          class="input-warning__text"
        >
          {{
            $t('terms.supplierTerms.generalTerms.paymentDueModal.typeNumberSmallerThan', {
              number: PAYMENT_DUE_PERSONALIZED_MAX + 1,
            })
          }}
        </span>
        <span
          v-else-if="validationError === INPUT_VALIDATION_REASONS.NUMBER_IS_SMALLER_THAN_MIN"
          class="input-warning__text"
        >
          {{
            $t('terms.supplierTerms.generalTerms.paymentDueModal.typeNumberBiggerThan', {
              number: PAYMENT_DUE_PERSONALIZED_MIN - 1,
            })
          }}
        </span>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button @click="handleSaveClicked">{{ $t('commons.save') }}</Button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon, NoticeIcon } from '@/assets/icons';

const PAYMENT_DUE_RADIO_STATE = {
  NET: 0,
  NET3: 3,
  NET7: 7,
  NET14: 14,
  NET15: 15,
  NET28: 28,
  NET30: 30,
  NET45: 45,
  NET60: 60,
};
const PAYMENT_DUE_RADIO_STATE_PERSONALIZED = 'personalized';
const PAYMENT_DUE_PERSONALIZED_MAX = 180;
const PAYMENT_DUE_PERSONALIZED_MIN = 2;

const INPUT_VALIDATION_REASONS = {
  INPUT_IS_NOT_A_VALID_NUMBER: 1,
  NUMBER_IS_BIGGER_THAN_MAX: 2,
  NUMBER_IS_SMALLER_THAN_MIN: 3,
};

const PREDEFINED_PAYMENT_DUE_OPTIONS_US = [
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET, eom: false },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET3, eom: false },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET7, eom: false },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET14, eom: false },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET28, eom: false },
];

const PREDEFINED_PAYMENT_DUE_OPTIONS_IL = [
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET, eom: true },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET15, eom: true },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET30, eom: true },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET45, eom: true },
  { paymentDueNet: PAYMENT_DUE_RADIO_STATE.NET60, eom: true },
];

export default {
  components: { Button, CloseIcon, NoticeIcon },
  props: {
    loading: { type: Boolean, default: false },
    paymentDueNet: {
      type: Number,
      default: PAYMENT_DUE_RADIO_STATE.NET,
    },
    eom: { type: Boolean, default: null },
    supplier: { type: String, required: true },
    countryCode: { type: String, required: true },
  },
  data() {
    const predefinedDueOptions =
      this.countryCode === 'US' ? PREDEFINED_PAYMENT_DUE_OPTIONS_US : PREDEFINED_PAYMENT_DUE_OPTIONS_IL;
    const eomByCountryCode = this.countryCode === 'US' ? false : true;

    const paymentDueNetTerm = this.paymentDueNet
      ? predefinedDueOptions.find((option) => option.paymentDueNet === this.paymentDueNet && option.eom === this.eom) ??
        PAYMENT_DUE_RADIO_STATE_PERSONALIZED
      : predefinedDueOptions[0];

    return {
      paymentDueNetTerm,
      personalizedPaymentDueNet: this.paymentDueNet,
      eomByCountryCode,
      PAYMENT_DUE_RADIO_STATE,
      PAYMENT_DUE_RADIO_STATE_PERSONALIZED,
      validationError: null,
      INPUT_VALIDATION_REASONS,
      PAYMENT_DUE_PERSONALIZED_MAX,
      PAYMENT_DUE_PERSONALIZED_MIN,
      predefinedDueOptions,
    };
  },
  computed: {
    currentPaymentDueNet() {
      let currDueNet;
      if (this.paymentDueNetTerm === PAYMENT_DUE_RADIO_STATE_PERSONALIZED) {
        currDueNet = Number(this.personalizedPaymentDueNet);
      } else {
        currDueNet = this.paymentDueNetTerm.paymentDueNet;
      }
      return currDueNet;
    },
    inputValidation() {
      const radioSelectionPersonalized = this.paymentDueNetTerm === PAYMENT_DUE_RADIO_STATE_PERSONALIZED;
      const personalizedPaymentToNum = Number(this.personalizedPaymentDueNet);

      let reason;
      if (!Number.isInteger(personalizedPaymentToNum)) {
        reason = INPUT_VALIDATION_REASONS.INPUT_IS_NOT_A_VALID_NUMBER;
      } else if (personalizedPaymentToNum > PAYMENT_DUE_PERSONALIZED_MAX) {
        reason = INPUT_VALIDATION_REASONS.NUMBER_IS_BIGGER_THAN_MAX;
      } else if (personalizedPaymentToNum < PAYMENT_DUE_PERSONALIZED_MIN) {
        reason = INPUT_VALIDATION_REASONS.NUMBER_IS_SMALLER_THAN_MIN;
      }

      const dueNetNotValid =
        !Number.isInteger(personalizedPaymentToNum) ||
        personalizedPaymentToNum > PAYMENT_DUE_PERSONALIZED_MAX ||
        personalizedPaymentToNum < PAYMENT_DUE_PERSONALIZED_MIN;

      return {
        valid: !(radioSelectionPersonalized && dueNetNotValid),
        reason,
      };
    },
  },
  watch: {
    paymentDueNetTerm: {
      handler() {
        this.validationError = null;
      },
    },
  },
  methods: {
    handleSaveClicked() {
      if (!this.inputValidation.valid) {
        this.validationError = this.inputValidation.reason;
      } else {
        this.$emit('submit', this.currentPaymentDueNet, this.eomByCountryCode);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-dialog__body {
  padding: 0;
}
.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

.personalized-selection {
  margin: 8px 28px;
  display: flex;
  align-items: center;
  gap: 8px;
  &__text {
    color: #1f284d;
  }
  &__input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  &__input-prefix {
    position: absolute;
    top: 7px;
    padding: 5px;
    [dir='rtl'] & {
      right: 0;
    }
    [dir='ltr'] & {
      left: 0;
    }
  }
  &__input {
    width: 70px;
    padding: 10px 15px;
    border-radius: 8px;
    border-color: #c9d5dd;
    border-style: solid;
  }
}

.input-warning {
  color: #e52044;
  margin: 8px 26px;
  &__icon {
    width: 16px;
    height: 16px;
  }
  &__text {
    margin: 0px 4px;
  }
}

.eom-checkbox {
  [dir='rtl'] & {
    margin-right: 1rem;
  }
  [dir='ltf'] & {
    margin-left: 1rem;
  }
}

:deep(.el-checkbox__label, .el-checkbox__input.is-checked + .el-checkbox__label) {
  font-weight: $font-weight-normal;
  color: $typography-primary !important;
}
</style>
